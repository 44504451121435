import { styled } from '@mui/material/styles';
export const Label = styled('span')(({ theme }) => `
  font-size: 0.875rem;
  color: ${theme.palette.text.primary};
`);
export const SecondaryText = styled('span')(({ theme }) => `
  white-space: nowrap;
  font-size: 0.75rem;
  color: ${theme.palette.text.secondary};
`);
