import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Box, Button, Divider, Drawer, IconButton, List, ListItem, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import TopbarProfile from './TopbarProfile';
import TopBarConnection from './TopBarConnection';
import Permissions from '../AdminSidebar/Permissions';
import { getEnvSettings } from '../../../config/environmentSettings';
import { BALANCES_STATE_UPDATE, WORKSPACE_FETCH_REQUEST } from '../../../redux/actionTypes/workspaceActions';
import { createStyles, makeStyles } from '@mui/styles';
import { SET_SELECTED_CLIENT } from '../../../redux/actionTypes/clientSearchActionTypes';
import { ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE } from '../../../redux/actionTypes/adminActionTypes';
import { getSelectedClient } from '../../../redux/selectors/clientSearchSelectors';
import { selectCustomer } from '../../../redux/actions/customersSearchActions';
import BalancesWidget from '../../Balances/BalancesWidget';
import { writeToLocalStorage } from '../../../shared/helpers/localStorageInterface';
import CustomerSelect from '@core/CustomerSelect';
import { Ellipsis } from '@shared/components/styled/ellipsis';
import { Label, SecondaryText } from '@shared/components/styled/labels';
const okcoinUsers = [
    'tobias.rethmeyer@steubing.com',
    'adrian.hurler@steubing.com',
    'paul.huelsmann@finexity.com',
    'patrick.grewe@plutos.de',
    'andreas.othmer@plutos.de',
];
const adminClientUpdate = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
const pageOfItemsUpdate = (pageOfItemsAdminOrders) => ({
    type: ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminOrders,
});
const finoaEnv = getEnvSettings().env === 'finoa';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        background: theme.palette.background.paper,
        boxShadow: `0 8px ${theme.palette.background.default}`,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        height: '60px',
        width: '100%',
        top: 0,
        zIndex: 10,
        fontSize: '0.875rem',
        '& .logo': {
            maxWidth: '170px',
            minWidth: '125px',
            width: '100%',
            height: '24px',
            display: 'inline-block',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            '&.dlt': {
                backgroundImage: theme.palette.mode === 'dark' ? `url(/img/logo/dlt_logo_light.svg)` : 'url(/img/logo/dlt_logo_dark.svg)',
            },
            '&.okcoin': {
                backgroundImage: `url(/img/logo/steubing_logo.png)`,
            },
            '&.finoa': {
                backgroundImage: theme.palette.mode === 'dark' ? `url(/img/logo/logo_finoa.svg)` : 'url(/img/logo/logo_finoa_l.svg)',
            },
        },
        '& .menu-item': {
            '& button': {},
            '&-active': {
                color: theme.palette.primary.main,
                '& button': {
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.getContrastText(theme.palette.secondary.light),
                },
            },
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
    },
    drawer: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.text.primary,
        },
        '& a.drawer__link-active': {
            color: theme.palette.primary.main,
        },
    },
    userSelect: {
        width: '333px',
        // width: '100%',
        // background: theme.palette.grey[100],
        // color: theme.palette.primary.main
        // height: '100%',
    },
}));
/* eslint-disable camelcase */
const TopbarWithNavigation = ({ features, masterTenant, clientInfo, selectedClient, selectedCustomer, isCustomer, balancesWidget, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [logoLink, setLogoLink] = useState('trading');
    const [logoClass, setLogoClass] = useState('dlt');
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [clientSelectValue, setClientSelectValue] = useState(null);
    const [customerSelectValue, setCustomerSelectValue] = useState(null);
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [balances, setBalancesOpended] = useState(balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned);
    const handleLinkClick = (dashboard) => {
        dispatch({ type: WORKSPACE_FETCH_REQUEST, dashboard });
        setDrawerOpened(false);
    };
    const handleNavigateToClient = () => {
        if (clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.value) {
            history.push(`/manage/clients/${clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.value}`);
        }
    };
    const handleNavigateToCustomer = () => {
        if (customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.value) {
            history.push(`/manage/customers/${customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.value}`);
        }
    };
    useEffect(() => {
        if (features && (features === null || features === void 0 ? void 0 : features.length) > 0 && !features.includes('order.read')) {
            setLogoLink('custody');
        }
    }, [features]);
    useEffect(() => {
        if (clientInfo && okcoinUsers.includes(clientInfo.email)) {
            setLogoClass('okcoin');
        }
        else if (clientInfo && finoaEnv) {
            setLogoClass('finoa');
        }
    }, [clientInfo]);
    useEffect(() => {
        const value = selectedClient
            ? {
                value: selectedClient.code,
                label: selectedClient.company_name || selectedClient.code,
                enabled: selectedClient.is_enabled,
            }
            : null;
        setClientSelectValue(value);
    }, [selectedClient]);
    useEffect(() => {
        const value = selectedCustomer
            ? {
                value: selectedCustomer.code,
                label: selectedCustomer.company_name || selectedCustomer.code,
                enabled: selectedCustomer.is_enabled,
            }
            : null;
        setCustomerSelectValue(value);
    }, [selectedCustomer]);
    const handleOpenBalances = () => {
        setBalancesOpended(!balances);
        if (balances) {
            dispatch({ type: BALANCES_STATE_UPDATE, pinned: false });
            writeToLocalStorage('balancesWidget', { pinned: false });
        }
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, gap: 2, width: '100%' },
            React.createElement(Box, { sx: { flex: '1 1 auto' } },
                React.createElement(Box, { sx: { display: { xs: 'none', md: 'flex' } } },
                    React.createElement(Link, { className: `logo ${logoClass}`, to: '/', onClick: () => handleLinkClick(logoLink) })),
                React.createElement(Box, { sx: { display: { xs: 'flex', md: 'none' } } },
                    React.createElement(IconButton, { edge: 'start', color: 'inherit', "aria-label": 'menu', onClick: () => setDrawerOpened(!drawerOpened), size: 'large' },
                        React.createElement(MenuIcon, null)))),
            React.createElement(Drawer, { anchor: 'left', open: drawerOpened, onClose: () => setDrawerOpened(false), classes: { paper: classes.drawer } },
                React.createElement("div", null,
                    React.createElement(List, { component: 'nav' },
                        React.createElement(Permissions, { features: features, permissions: ['order.read', 'client_rfq_settings.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('tradingRfq') },
                                React.createElement(NavLink, { className: 'drawer__link', activeClassName: 'drawer__link-active', exact: true, to: '/rfq' }, "RFQ Trading"))),
                        masterTenant && !finoaEnv ? (React.createElement(Permissions, { features: features, permissions: ['order.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('trading') },
                                React.createElement(NavLink, { exact: true, to: '/dma', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('trading') }, "DMA Trading")))) : null,
                        React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('custody') },
                                React.createElement(NavLink, { to: '/custody', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('custody') }, "Custody")))))),
            React.createElement(Box, { sx: {
                    display: { xs: 'none', md: 'flex' },
                    gap: 1,
                    flex: '1 0 auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                } },
                React.createElement(Permissions, { features: features, permissions: ['order.read', 'client_rfq_settings.read'] },
                    React.createElement(NavLink, { exact: true, to: '/rfq', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('tradingRfq') },
                        React.createElement(Button, { variant: 'text', color: 'info', sx: { textTransform: 'none' } }, "RFQ Trading"))),
                masterTenant && !finoaEnv ? (React.createElement(Permissions, { features: features, permissions: ['order.read'] },
                    React.createElement(NavLink, { exact: true, to: '/dma', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('trading') },
                        React.createElement(Button, { variant: 'text', color: 'info', sx: { textTransform: 'none' } }, "DMA Trading")))) : null,
                React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.read'] },
                    React.createElement(NavLink, { to: '/custody', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('custody') },
                        React.createElement(Button, { variant: 'text', color: 'info', sx: { textTransform: 'none' } }, "Custody"))),
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, mx: 2 },
                    React.createElement(Button, { variant: 'outlined', color: 'info', onClick: handleOpenBalances, sx: { textTransform: 'none' } }, "Balances")),
                React.createElement(TopBarConnection, null)),
            React.createElement(BalancesWidget, { open: !!balances, onClose: () => handleOpenBalances() }),
            !isCustomer ? (React.createElement(Box, { className: classes.userSelect, sx: {
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    flex: '1 0 auto',
                    justifyContent: 'flex-end',
                    mr: 2,
                }, gap: 2 },
                masterTenant ? (React.createElement(React.Fragment, null,
                    React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', textAlign: 'right' } },
                        React.createElement(React.Fragment, null,
                            React.createElement(Ellipsis, { sx: { cursor: (clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.label) ? 'pointer' : 'auto', maxWidth: '150px' } },
                                React.createElement(Label, { onClick: () => handleNavigateToClient(), sx: { textDecoration: (clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.label) ? 'underline' : 'none' } }, (clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.label) || 'Not set')),
                            React.createElement(Typography, { variant: 'caption' },
                                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 } },
                                    React.createElement(SecondaryText, null, "Client"))))),
                    React.createElement(Divider, { orientation: 'vertical', flexItem: true }))) : null,
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(React.Fragment, null,
                        React.createElement(Ellipsis, { sx: { cursor: (customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.label) ? 'pointer' : 'auto', maxWidth: '150px' } },
                            React.createElement(Label, { onClick: () => handleNavigateToCustomer(), sx: { textDecoration: (customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.label) ? 'underline' : 'none' } }, (customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.label) || 'Not set')),
                        React.createElement(Typography, { variant: 'caption' },
                            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 1 } },
                                React.createElement(SecondaryText, null, "Customer"))))),
                React.createElement(IconButton, { onClick: () => setSelectCustomer(!selectCustomer) },
                    React.createElement(EditIcon, { fontSize: 'small' })),
                React.createElement(CustomerSelect, { onClose: () => setSelectCustomer(false), opened: selectCustomer }))) : null,
            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-end' } },
                React.createElement(TopbarProfile, null)))));
};
const stateToProps = (state) => ({
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    clients: state.clientSearch.allClients,
    customers: state.customersSearch.customers,
    selectedClient: getSelectedClient(state),
    selectedCustomer: state.customersSearch.selectedCustomer,
    isCustomer: !!state.client.customer_code,
    balancesWidget: state.workspace.balances,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchpageOfItemsAdminOrders: (page) => dispatch(pageOfItemsUpdate(page)),
    dispatchClientSelect: (client) => dispatch(adminClientUpdate(client)),
    dispatchCustomerSelect: (customer) => dispatch(selectCustomer(customer)),
});
export default connect(stateToProps, mapDispatchToProps)(TopbarWithNavigation);
