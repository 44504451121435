import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const ClientSearchSelect = ({ onSelect, selectedValue, clients, label, placeholder }) => {
    const [currentValue, setCurrentValue] = useState(selectedValue);
    const handleClientSelect = (client) => {
        setCurrentValue(client);
        if (onSelect) {
            onSelect(client);
        }
    };
    useEffect(() => {
        setCurrentValue(selectedValue);
    }, [selectedValue]);
    return (React.createElement(Autocomplete, { options: clients, autoComplete: false, value: currentValue, fullWidth: true, onChange: (event, newValue) => handleClientSelect(newValue), filterOptions: (x, { inputValue }) => x.filter((v) => v.label.includes(inputValue) || v.value.includes(inputValue)), isOptionEqualToValue: (option) => option.value === (currentValue === null || currentValue === void 0 ? void 0 : currentValue.value), renderOption: (props, option) => (React.createElement(Box, Object.assign({ display: 'flex', alignItems: 'center', component: 'li' }, props, { key: option.value }),
            React.createElement(FiberManualRecordIcon, { fontSize: 'small', color: option.enabled ? 'primary' : 'error' }),
            ' ',
            React.createElement("span", null, option.label))), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { size: 'small', variant: 'outlined', label: label, placeholder: placeholder, inputProps: Object.assign(Object.assign({}, params.inputProps), { id: `select-${label}`, autoComplete: 'off' }) }))) }));
};
export default ClientSearchSelect;
