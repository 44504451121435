import { Box, IconButton, TextField, Typography, useTheme } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Messages from '../../shared/helpers/errorMessages';
import { composeErrorMessage } from '../../shared/helpers/interceptors';
import showNotification from '../../shared/helpers/notifications';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { selectCustomer } from '../../redux/actions/customersSearchActions';
import { SET_SELECTED_CLIENT } from '../../redux/actionTypes/clientSearchActionTypes';
import ClientSearchSelect from '../../containers/Layout/AdminSidebar/components/ClientSearch';
import { getSelectedClient } from '../../redux/selectors/clientSearchSelectors';
const adminClientUpdate = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
const formSchema = yup.object({
    uuid: yup.string().matches(/^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/),
});
const CustomerSelect = (props) => {
    const { clients, customers, selectedCustomer, selectedClient, masterTenant, dispatchCustomerSelect, dispatchClientSelect, opened, onClose, } = props;
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [customerSelectValue, setCustomerSelectValue] = useState(null);
    const [clientSelectValue, setClientSelectValue] = useState(null);
    const [customerById, setCustomerById] = useState(null);
    const { register, resetField, trigger, setValue, watch, formState: { errors }, } = useForm({
        resolver: yupResolver(formSchema),
    });
    const theme = useTheme();
    const uuid = watch('uuid');
    useEffect(() => {
        setIsVisible(opened);
    }, [opened]);
    useEffect(() => {
        if (!isVisible) {
            onClose();
        }
    }, [isVisible]);
    useEffect(() => {
        if (selectedClient) {
            setClientSelectValue({
                label: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.company_name) || (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.code),
                value: selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.code,
                enabled: selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.is_enabled,
            });
        }
        else {
            setClientSelectValue(null);
        }
    }, [selectedClient]);
    useEffect(() => {
        if ((clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.value) !== (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.code)) {
            dispatchClientSelect((clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.value) || null);
            setCustomerById(null);
            resetField('uuid');
        }
    }, [clientSelectValue]);
    useEffect(() => {
        if (!customerById) {
            const newCustomer = customers.find((c) => c.code === (customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.value));
            dispatchCustomerSelect(newCustomer || null);
        }
        else {
            setCustomerById(null);
        }
    }, [customerSelectValue]);
    useEffect(() => {
        if (customerById) {
            dispatchClientSelect((customerById === null || customerById === void 0 ? void 0 : customerById.client_code) || null);
            dispatchCustomerSelect(customerById);
            setCustomerSelectValue({
                value: customerById.code,
                label: customerById.company_name || customerById.code,
                enabled: customerById === null || customerById === void 0 ? void 0 : customerById.is_enabled,
            });
        }
    }, [customerById]);
    useEffect(() => {
        const value = selectedCustomer
            ? {
                value: selectedCustomer.code,
                label: selectedCustomer.company_name || selectedCustomer.code,
                enabled: selectedCustomer.is_enabled,
            }
            : null;
        setCustomerSelectValue(value);
    }, [selectedCustomer]);
    const getCustomer = (id) => {
        setLoading(true);
        const requestConfig = {
            url: `/customers/${id}?details=true`,
            method: 'get',
        };
        axios
            .request(requestConfig)
            .then((response) => {
            setCustomerById(response.data);
            setLoading(false);
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTOMER_FETCH);
            showNotification({ message, color: 'error' });
        });
    };
    const mapUsers = (users) => {
        return users === null || users === void 0 ? void 0 : users.map((c) => ({
            value: c.code,
            label: c.company_name || c.code,
            enabled: c.is_enabled,
        }));
    };
    const onFindCustomer = () => {
        if (uuid) {
            getCustomer(uuid);
        }
    };
    return (React.createElement(Box, { sx: {
            position: 'fixed',
            top: '0',
            p: 2,
            display: opened ? 'block' : 'none',
            background: theme.palette.background.paper,
            boxShadow: `0 0 0 4px ${theme.palette.background.default}`,
        } },
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
            React.createElement(Typography, { variant: 'subtitle1' }, "Select user"),
            React.createElement(IconButton, { onClick: () => onClose() },
                React.createElement(ExpandLessIcon, { fontSize: 'small' }))),
        React.createElement(Box, null, masterTenant ? (React.createElement(Box, { sx: {
                '& .MuiTextField-root': { my: 1, width: '36ch' },
            } },
            React.createElement(ClientSearchSelect, { label: 'Client', placeholder: 'Select client', selectedValue: clientSelectValue, clients: mapUsers(clients), onSelect: (client) => {
                    setClientSelectValue(client);
                } }))) : null),
        React.createElement(Box, null,
            React.createElement(Box, { sx: {
                    '& .MuiTextField-root': { my: 1, width: '36ch' },
                } },
                React.createElement(ClientSearchSelect, { label: 'Customer', placeholder: 'Select customer', selectedValue: customerSelectValue, clients: mapUsers(customers), onSelect: (customer) => {
                        setCustomerSelectValue(customer);
                        setValue('uuid', '');
                    } })),
            React.createElement(Box, { component: 'form', sx: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    '& .MuiTextField-root': { my: 1, width: '36ch' },
                }, noValidate: true, autoComplete: 'off' },
                React.createElement(TextField, Object.assign({ label: 'Customer UUID', size: 'small', fullWidth: true, InputLabelProps: { shrink: true }, error: !!errors['uuid'], placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' }, register('uuid'), { onChange: (e) => {
                        setValue('uuid', e.target.value);
                        trigger('uuid');
                    } })),
                React.createElement(LoadingButton, { loading: loading, disabled: !uuid || !!errors['uuid'], onClick: onFindCustomer }, "Find")))));
};
const stateToProps = (state) => ({
    customers: state.customersSearch.customers,
    selectedCustomer: state.customersSearch.selectedCustomer,
    selectedClient: getSelectedClient(state),
    clients: state.clientSearch.allClients,
    masterTenant: state.client.masterTenant,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchCustomerSelect: (customer) => dispatch(selectCustomer(customer)),
    dispatchClientSelect: (client) => dispatch(adminClientUpdate(client)),
});
export default connect(stateToProps, mapDispatchToProps)(CustomerSelect);
